import * as z from 'zod';
import compact from 'lodash/compact';
import qs from 'query-string';
import {
  ExtendedSpace,
  Space,
  SpaceQueryInput,
  SpaceQueryResult,
  SpaceSchema,
} from '@cocoplatform/coco-rtc-shared';
import { useEffect, useMemo } from 'react';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { SyncState } from 'utils/sync-state';
import { reportServerError } from 'utils/report-error';
import { atom, useRecoilState } from 'recoil';
import uniq from 'lodash/uniq';
import { browserStorage } from 'utils/browser-storage';
import { Evt } from 'evt';
import { sortBy } from 'lodash';
import { msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CocoLogger } from '@cocoplatform/coco-logger';
import { WorldCorridor } from '@cocoplatform/coco-rtc-shared';
import { treeItemClasses } from '@mui/lab';

export interface SpaceSectionPreset {
  id: string;
  label: string;
  isDefault?: boolean;
  query?: Partial<SpaceQueryInput>;
}

export type SortDir = 'asc' | 'desc';

export interface SortCriteria {
  label: string;
  column: string;
  dir: SortDir;
  isDefault?: boolean;
}

export interface SortInput {
  column: string;
  dir: SortDir;
}

export interface SpaceSection {
  id: string;
  label: string;
  altLabel: string;
  description?: string;
  emptyDescription?: string;
  horizontalDescription?: string;
  infoText?: string;
  showRecencyTag?: boolean;
  presets: SpaceSectionPreset[];
  sortCriteria: SortCriteria[];
}

export const spaceMappingAtom = atom({
  key: 'spaces',
  default: {} as Record<string, Space & WorldCorridor>,
});

export const getSpaceSections = (): SpaceSection[] => [
  {
    id: 'new-spaces-to-join',
    description: t`Any new CoCo jams that you have been invited to join`,
    label: t`New Jams to Join`,
    presets: [
      {
        id: 'all',
        label: t`All`,
        query: {
          isNewSpaceToJoin: true,
        },
      },
    ],
    sortCriteria: [
      {
        label: t`Last Created`,
        isDefault: true,
        column: 'expectedToStartAt',
        dir: 'desc',
      },
      {
        label: t`Last Visited`,
        column: 'lastVisitedAt',
        dir: 'desc',
      },
    ],
  },
  {
    id: 'my-coco-spaces',
    description: t`All your CoCo jams`,
    emptyDescription: t`Get started with creating your first CoCo jam.`,
    label: t`My CoCo Jams`,
    presets: [
      {
        id: 'all',
        label: t`All`,
        query: {
          isMySpace: true,
        },
      },
      {
        id: 'hosting',
        label: t`Created`,
        query: {
          isMySpace: true,
          isHosting: true,
        },
      },
      {
        id: 'participated',
        label: t`Participated`,
        query: {
          isMySpace: true,
          isParticipating: true,
        },
      },
      {
        id: 'bookmarked',
        label: t`Starred`,
        query: {
          isMySpace: true,
          isBookmarked: true,
        },
      },
    ],
    sortCriteria: [
      {
        label: t`Last Created`,
        column: 'expectedToStartAt',
        dir: 'desc',
      },
      {
        label: t`Last Visited`,
        column: 'lastVisitedAt',
        isDefault: true,
        dir: 'desc',
      },
    ],
  },
  {
    id: 'explore-other-spaces',
    description: t`CoCo jams created by your peers`,
    emptyDescription: t`CoCo jams created by your peers will appear here.`,
    label: t`Jams by Peers`,
    presets: [
      {
        id: 'all',
        label: t`All`,
        query: {
          spacesByOthers: true,
        },
      },
      {
        id: 'invited',
        label: t`Invited`,
        query: {
          spacesByOthers: true,
          isInvited: true,
        },
      },
      {
        id: 'bookmarked',
        label: t`Starred`,
        query: {
          spacesByOthers: true,
          isBookmarked: true,
        },
      },
    ],
    sortCriteria: [
      {
        label: t`Last Created`,
        isDefault: true,
        column: 'expectedToStartAt',
        dir: 'desc',
      },
      {
        label: t`Last Visited`,
        column: 'lastVisitedAt',
        dir: 'desc',
      },
    ],
  },
];

export const getWorldCorridorSectionNames = (sectionId: string) => {
  const sections = {
    'new-spaces-to-join': t`New Jams to Join`,
    'my-coco-spaces': t`My CoCo Jams`,
    'explore-other-spaces': t`Jams by Peers`,
  };
  return sections[sectionId];
};

export const getWorldCorridorSections = (): SpaceSection[] => [
  {
    id: 'coco-jams',
    description: t`CoCo jams by creators around the world`,
    horizontalDescription: t`Remix with friends to make your own.`,
    label: 'Featured Jams',
    altLabel: 'Featured Jams',
    infoText: t`Jams showcase collaborative spaces shared by the CoCo Team and communities around the world. Click to explore and remix any space with friends in your community.`,
    sortCriteria: [
      {
        label: t`Last Created`,
        isDefault: true,
        column: 'expectedToStartAt',
        dir: 'desc',
      },
    ],
    presets: [
      {
        id: 'all',
        label: t`All`,
        query: {
          isJam: true,
          isSpaceToRemix: true,
          seed: 1,
          isApproved: true,
        },
      },
    ],
  },
];

const spaceSummaryKey = (id: string) => `coco:u:$user:space:summary:${id}`;

const spaceQueryResultsKey = (params: SpaceQueryParams) => {
  return compact([
    'coco:u:$user',
    `q:${params.query}`,
    params.communityId ? `C:${params.communityId}` : null,
    params.userId ? `U:${params.userId}` : null,
    compact(params.filter).join('|'),
    params.sortCriteria,
  ]).join(':');
};

export const getLocallyPersistedSpaceSummary = (id: string) => {
  const raw = browserStorage.getItem(spaceSummaryKey(id));
  if (!raw) return null;
  try {
    return ExtendedSpace.parse(JSON.parse(raw));
  } catch (e) {
    console.error(e);
    return null;
  }
};

interface SpaceQueryParams {
  sectionId: string;
  communityId?: string;
  filter: [string, string];
  sortCriteria: string;
  sortDir: SortDir;
  userId?: string;
  query?: string;
  themeIds?: string[];
  overrideParams?: Partial<SpaceQueryInput>;
}

interface SpaceParams {
  spaceId?: string;
}

export const useSpaceSummary = ({ spaceId }: SpaceParams) => {
  const { _ } = useLingui();
  const [spaceMapping, setSpaceMapping] = useRecoilState(spaceMappingAtom);
  const [, setSpaceQueryState] = useRecoilState(spaceQueryStateAtom);
  const space: Space | null = spaceId ? spaceMapping[spaceId] : null;
  const replaceLocal = (space: Space) => {
    setSpaceMapping((spaces) => ({
      ...spaces,
      [space.id]: space,
    }));
  };

  const patchLocal = (id: string, patch: (space: Space) => Space) => {
    setSpaceMapping((spaces) => {
      const space = spaces[id];
      if (!space) return spaces;
      return {
        ...spaces,
        [id]: patch(space),
      };
    });
  };

  const setLocallyBookmarked = (isBookmarked: boolean) => {
    if (!spaceId) return;
    setSpaceMapping((spaces) => ({
      ...spaces,
      [spaceId]: {
        ...spaces[spaceId],
        isBookmarked,
        bookmarkCount: Math.max(
          0,
          (spaces[spaceId].bookmarkCount ?? 0) + (isBookmarked ? 1 : -1),
        ),
      },
    }));
  };

  const toggleBookmark = async () => {
    if (!space) throw new Error(_(msg`Space not available`));
    if (space.isBookmarked) {
      setLocallyBookmarked(false);
      try {
        await httpClient.delete(`/spaces/${spaceId}/bookmark`);
      } catch (error) {
        reportServerError({
          error,
          title: _(msg`Failed to remove space bookmark`),
        });
        setLocallyBookmarked(true);
      }
    } else {
      setLocallyBookmarked(true);
      try {
        await httpClient.post(`/spaces/${spaceId}/bookmark`);
      } catch (error) {
        reportServerError({
          error,
          title: _(msg`Failed to bookmark space`),
        });
        setLocallyBookmarked(false);
      }
    }
  };

  const conclude = async (opts?: {
    end?: boolean;
    lock?: boolean;
    archive?: boolean;
    delete?: boolean;
  }) => {
    if (!spaceId) return;
    try {
      await httpClient.delete(`/spaces/${spaceId}?${qs.stringify(opts ?? {})}`);
      if (opts?.delete) {
        setSpaceMapping(({ [spaceId]: _deletedSpace, ...spaces }) => spaces);
      } else {
        setSpaceMapping(({ [spaceId]: s, ...spaces }) => ({
          ...spaces,
          [spaceId]: {
            ...s,
            archivedAt: opts?.archive ? +new Date() : undefined,
            endedAt: +new Date(),
          },
        }));
      }
      setSpaceQueryState((qs) =>
        Object.fromEntries(
          Object.entries(qs).map(([key, mapping]) => {
            if (!mapping) return [key, mapping];
            const idx = mapping.spaceIds.indexOf(spaceId);
            if (idx >= 0) {
              return [
                key,
                {
                  ...mapping,
                  count: mapping.count - 1,
                  spaceIds: mapping.spaceIds.filter((it) => it !== spaceId),
                },
              ];
            }
            return [key, mapping];
          }),
        ),
      );
    } catch (error) {
      reportServerError({
        error,
        title: _(msg`Failed to archive space`),
      });
    }
  };

  const restore = async () => {
    if (!spaceId) return;
    try {
      await httpClient.post(`/spaces/${spaceId}/restore`);
      setSpaceMapping(({ [spaceId]: s, ...spaces }) => ({
        ...spaces,
        [spaceId]: {
          ...s,
          archivedAt: undefined,
          endedAt: +new Date(),
        },
      }));
    } catch (error) {
      reportServerError({
        error,
        title: _(msg`Failed to restore space`),
      });
    }
  };

  const refetch = async (opts?: { visit: boolean }) => {
    try {
      const { data: space } = await httpClient.get(`/spaces/${spaceId}`, {
        params: {
          visit: opts?.visit ? 'true' : undefined,
        },
      });
      replaceLocal(space);
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to fetch space details`),
        error,
      });
    }
  };

  return {
    space,
    toggleBookmark,
    conclude,
    restore,
    refetch,
    replaceLocal,
    patchLocal,
  };
};

interface SpaceQueryState {
  syncState: SyncState;
  count: number;
  resultsKey?: string | null;
  spaceIds: string[];
}

const defaultSpaceQueryState: SpaceQueryState = {
  syncState: 'pending',
  count: 0,
  spaceIds: [],
};

const spaceQueryStateAtom = atom({
  key: 'spaceQueryState',
  default: {} as Record<string, SpaceQueryState | undefined>,
});

export const useSpacesQuery = (
  params: SpaceQueryParams,
  sharedFetchParams?: { limit: number },
) => {
  const { _ } = useLingui();
  const queryKey = `${params.communityId}:${params.sectionId}`;

  const [spaceQueryState, setSpaceQueryState] =
    useRecoilState(spaceQueryStateAtom);

  const curSpaceQueryState =
    (queryKey ? spaceQueryState[queryKey] : null) ?? defaultSpaceQueryState;

  const setCurSpaceQueryState = (
    update: (s: SpaceQueryState) => SpaceQueryState,
  ) =>
    setSpaceQueryState((mapping) =>
      queryKey
        ? {
          ...mapping,
          [queryKey]: update(mapping[queryKey] ?? defaultSpaceQueryState),
        }
        : mapping,
    );

  const { syncState, count, spaceIds, resultsKey } = curSpaceQueryState;

  const setSyncState = (syncState: SyncState) =>
    setCurSpaceQueryState((s) => ({ ...s, syncState }));

  const setCount = (count: number) =>
    setCurSpaceQueryState((s) => ({ ...s, count }));

  const setResultsKey = (resultsKey?: string | null) =>
    setCurSpaceQueryState((s) => ({ ...s, resultsKey }));

  const setSpaceIds = (update: (spaceIds: string[]) => string[]) =>
    setCurSpaceQueryState((s) => ({ ...s, spaceIds: update(s.spaceIds) }));

  const [spaceMapping, setSpaceMapping] = useRecoilState(spaceMappingAtom);

  const spaces = useMemo(() => {
    return compact(spaceIds.map((id) => spaceMapping[id]));
  }, [spaceIds, spaceMapping]);

  useEffect(() => {
    if (syncState === 'loaded') {
      locallyPersistQueryResults(spaceIds, params);
    }
  }, [syncState, spaceIds]);

  const updateSpaceMapping = (spaces: Space[], overwrite = true) => {
    setSpaceMapping((prevMapping) => {
      const newMapping = { ...prevMapping };
      for (const space of spaces) {
        if (!newMapping[space.id] || overwrite) newMapping[space.id] = space;
        locallyPersistSpace(space);
      }
      return newMapping;
    });
  };

  const offset = spaces.length;

  const restoreLocal = () => {
    const spaces = assimilateLocallyPersistedQueryResults(params);
    if (!spaces) return;
    setSpaceIds(() => spaces.map((space) => space.id));
    updateSpaceMapping(spaces, false);
  };

  const fetchSpaces = async (
    handleResult: (result: SpaceQueryResult) => void,
    extraParams?: Partial<SpaceQueryInput>,
  ) => {
    setSyncState('loading');
    try {
      const resp = await httpClient.get('/spaces', {
        params: {
          ...getSpaceSections()
            .concat(getWorldCorridorSections())
            .find((section) => {
              return section.id === params.filter[0];
            })
            ?.presets?.find((preset) => {
              return preset.id === params.filter[1];
            })?.query,
          includeCollaborators: true,
          sortBy: params.sortCriteria,
          sortDir: params.sortDir,
          offset,
          communityId: params.communityId,
          query: params.query,
          participantId: params.userId,
          themeIds: params.themeIds,
          limit: sharedFetchParams?.limit,
          ...extraParams,
          ...params.overrideParams,
        },
      });
      const result = resp.data as SpaceQueryResult;
      setSyncState('loaded');
      handleResult(result);
    } catch (error) {
      setSyncState('failed');
      // eslint-disable-next-line lingui/no-unlocalized-strings
      CocoLogger.error('Failed to fetch spaces', error);
      // reportServerError({
      //   title: _(msg`Failed`),
      //   error,
      // });
    }
  };

  const fetchInitialSpaces = async (opts?: { skipIfLoaded: boolean }) => {
    const nextResultsKey = spaceQueryResultsKey(params);
    if (
      opts?.skipIfLoaded &&
      syncState === 'loaded' &&
      resultsKey === nextResultsKey
    ) {
      return;
    }
    if (syncState !== 'loaded') {
      setCount(0);
      restoreLocal();
    }
    fetchSpaces(
      (result) => {
        setCount(result.count ?? 0);
        let spaceIds = result.spaces.map((space) => space.id);
        if (params.sectionId === 'new-spaces-to-join') {
          // Move spaces where you are invited to join to the top
          const invitedSpacesIds = sortBy(
            result.spaces.filter((space) => {
              return space?.isCollaborator;
            }),
            [
              function (o) {
                return -o.createdAt;
              },
            ],
          ).map((space) => space.id);

          const otherSpacesIds = sortBy(
            result.spaces.filter((space) => {
              return !space?.isCollaborator;
            }),
            [
              function (o) {
                return -o.createdAt;
              },
            ],
          ).map((space) => space.id);

          spaceIds = invitedSpacesIds.concat(otherSpacesIds);
        }
        updateSpaceMapping(result.spaces);
        setResultsKey(nextResultsKey);
        setSpaceIds(() => spaceIds);
      },
      {
        offset: 0,
      },
    );
  };

  const fetchNextSpaces = async () => {
    if (syncState === 'loading') return;
    if (count != null && offset >= count) return;
    const resultsKey = spaceQueryResultsKey(params);

    fetchSpaces((result) => {
      if (offset === 0) {
        const spaceIds = result.spaces.map((space) => space.id);
        updateSpaceMapping(result.spaces);
        setSpaceIds(() => spaceIds);
      } else {
        setSpaceIds((spaceIds) => {
          return uniq(spaceIds.concat(result.spaces.map((space) => space.id)));
        });
        updateSpaceMapping(result.spaces);
      }
      setResultsKey(resultsKey);
    });
  };

  return {
    data: { count, spaces },
    params,
    hasMore: count != null && count > spaces.length,
    syncState,
    fetchNextSpaces,
    fetchInitialSpaces,
  };
};

export type SpaceQueryHandle = ReturnType<typeof useSpacesQuery>;

const assimilateLocallyPersistedQueryResults = (params: SpaceQueryParams) => {
  const key = spaceQueryResultsKey(params);
  const raw = key ? browserStorage.getItem(key) : null;
  if (!raw) return null;
  const spaceIds = z.string().array().parse(JSON.parse(raw));
  const spaces: Space[] = [];
  for (const id of spaceIds) {
    const space = getLocallyPersistedSpaceSummary(id);
    if (!space) return null;
    spaces.push(space);
  }
  return spaces;
};

const locallyPersistQueryResults = (
  spaceIds: string[],
  params: SpaceQueryParams,
) => {
  const key = spaceQueryResultsKey(params);
  if (!key) return;
  browserStorage.setItem(key, JSON.stringify(spaceIds));
};

const locallyPersistSpace = (space: Space) => {
  try {
    browserStorage.setItem(spaceSummaryKey(space.id), JSON.stringify(space));
  } catch (e) {
    console.error(t`Failed to locally persist space`);
    console.error(e);
  }
};

export const spaceCreatedEvt = new Evt<{ space: Space }>();
export const spaceEndedEvt = new Evt<{ space: Space }>();
export const worldCorridorSubmissionEvt = new Evt<{
  submission: Space & WorldCorridor;
}>();
